.highlight-text {	
    color: #F57F17;	
    ul {
        color: #F57F17;
    }
}	

.highlighted {	
    color: #1488E6	
}	

.editorBlockquote {	
    font-style: italic;	
    color: rgba(0,0,0,.6);	
    font-size: 17.5px;	
    border-left: 5px solid #C4CCD3;	
    font-family: "Trebuchet MS","Helvetica Neue",Helvetica,Tahoma,sans-serif;	
    line-height: 24px;	
    padding-left: 20px;	
  }	

.public-DraftEditor-content {	
    min-height: 200px;	
}	


.editorParagraphMargin {	
    margin-bottom: 16px;
}	

.CenterAlign div {
    text-align: center;
    margin-bottom: 16px;
}

.LeftAlign div {
    margin-bottom: 16px;
    text-align: left;
}

.RightAlign div {
    margin-bottom: 16px;
    text-align: right;
}
